import React from "react";

export default function SuccessPage() {
  return (
    <div className="container">
      <h1>Success! Checking for your BitWhip...</h1>
      <h2>
        You may now leave this page. If you do not receive your roles within 1
        minute, then retry the process!
      </h2>
      <button
        className="submitButton"
        onClick={() => (window.location.href = "/")}
      >
        Retry
      </button>
    </div>
  );
}
