import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Transaction, Message } from "@solana/web3.js";
import base58 from "bs58";
import React from "react";
import { API_ENDPOINT } from "./constants/constants";

export default function Home() {
  const [params, setSearch] = useSearchParams();

  const [discId, setDiscId] = useState(undefined);

  const [discUsername, setDiscUsername] = useState(undefined);

  const wallet = useWallet();

  const submitButton = useRef(null);

  const shortenAddress = (addr: string, digits: number) => {
    return addr.slice(0, digits) + "....." + addr.slice(-digits, addr.length);
  };

  const submitWallet = useCallback(async () => {
    if (wallet.publicKey && !submitButton.current.disabled) {
      submitButton.current.disabled = true;
      try {
        const sig = base58.encode(
          await wallet.signMessage!(
            Uint8Array.from(
              Buffer.from("I AM MY BITWHIP AND MY BITWHIP IS ME!")
            )
          )
        );
        console.log(`Signature: ${sig}`);
        // return;
        if (sig) {
          const { error, success } = await (
            await fetch(`${API_ENDPOINT}/submitForHolderVerif`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                discordId: discId,
                wallet: wallet.publicKey.toBase58(),
                signature: sig,
              }),
            })
          ).json();
          if (success) {
            window.location.href = "/success";
          } else if (error) {
            window.location.href = "/error";
          }
        }
      } catch {
        window.location.href = "/error";
      }
      submitButton.current.disabled = false;
    }
  }, [wallet]);

  useEffect(async () => {
    console.log(wallet);
    if (params.has("error")) {
      window.location.href = "/error";
      return;
    }
    if (params.has("code")) {
      try {
        const { discordId, username } = await (
          await fetch(`${API_ENDPOINT}/getIdFromCode`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code: params.get("code") }),
          })
        ).json();
        document.cookie = `discordId=${discordId}`;
        setDiscId(discordId);
        setDiscUsername(username);
      } catch (e) {
        console.log(`Error: ${e}`);
        window.location.href = "/error";
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="mainBox">
        <h1>BitWhips Holder Verification</h1>
        <h1>Discord: {discUsername}</h1>
        {!wallet.connected && <WalletMultiButton />}
        {wallet.connected && wallet.publicKey && discId && (
          <div className="container">
            <h1>Wallet: {shortenAddress(wallet.publicKey.toBase58(), 5)}</h1>
            <button
              className="submitButton"
              onClick={submitWallet}
              ref={submitButton}
            >
              Verify
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
