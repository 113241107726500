import { useEffect } from "react";
import React from "react";

export default function DiscordLogin() {
  const redirect =
    "https://discord.com/api/oauth2/authorize?client_id=940761522781683793&redirect_uri=https%3A%2F%2Fverify.bitwhips.io%2FdiscordLogin&response_type=code&scope=identify";

  // const redirect =
  //     'https://discord.com/api/oauth2/authorize?client_id=940761522781683793&redirect_uri=http%3A%2F%2F192.168.0.187%3A3000%2FdiscordLogin&response_type=code&scope=identify';

  useEffect(() => {
    window.location.href = redirect;
  }, []);

  return (
    <div className="container">
      <h1>Logging in with Discord...</h1>
    </div>
  );
}
