import React from "react";

export default function ErrorPage() {
  return (
    <div className="container">
      <h1>There was an error! Please try again!</h1>
      <button
        className="submitButton"
        onClick={() => (window.location.href = "/")}
      >
        Retry
      </button>
    </div>
  );
}
