import ReactDOM from "react-dom";
import LinkPhantomApp from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DiscordLogin from "./DiscordLogin";
import SuccessPage from "./SuccessPage";
import "./solstyles.css";
import "./styles.css";
import ErrorPage from "./ErrorPage";
import React from "react";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<DiscordLogin />} />
      <Route path="/discordLogin" element={<LinkPhantomApp />} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
